'use client';
import { useCallback, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import useDetectUserInteraction from '@/hooks/useDetectUserInteraction';

const PWA_POPUP_NEXT_KEY = 'pwa-popup-next';
const PERIOD = 1 * 30 * 24 * 60 * 60 * 1000; // 1 month
const PWAInstallPopup = dynamic(() => import('./PWAInstallPopup'), { ssr: false });

function isRunningStandalone() {
  return window.matchMedia('(display-mode: standalone)').matches;
}

function isSafariStandalone() {
  // @ts-ignore
  return 'standalone' in navigator && navigator.standalone === true;
}

const PWAInstallPromt = () => {
  const [installed, setInstalled] = useState(true);
  const [installEvent, setInstallEvent] = useState<Event | null>(null);
  const [open, setOpen] = useState(false);

  const onToggle = useCallback((val: boolean) => {
    const nextTime = parseInt(`${localStorage.getItem(PWA_POPUP_NEXT_KEY)}`);
    console.log({ nextTime });
    if (!val) {
      setOpen(val);
    }

    if (Number.isNaN(nextTime) || nextTime < Date.now()) {
      if (val) {
        setOpen(val);
        localStorage.setItem(PWA_POPUP_NEXT_KEY, `${Date.now() + PERIOD}`);
      }
    }
  }, []);

  const ready = useDetectUserInteraction();
  useEffect(() => {
    if (ready) {
      onToggle(ready);
    }
  }, [onToggle, ready]);

  useEffect(() => {
    // Only relevant for browsers that support installation.
    if ('BeforeInstallPromptEvent' in window) {
      console.log('pwa: BeforeInstallPromptEvent');
      // Variable to stash the "BeforeInstallPromptEvent".

      window.addEventListener('beforeinstallprompt', (event) => {
        // Do not show the install prompt quite yet.
        event.preventDefault();
        // Stash the "BeforeInstallPromptEvent" for later.
        setInstallEvent(event);
        setInstalled(false);
        // Enable the install button.
        console.log('pwa: beforeinstallprompt');
      });

      // The user can decide to ignore the install button
      // and just use the browser prompt directly. In this case
      // likewise run "onInstall()".
      window.addEventListener('appinstalled', () => {
        console.log('pwa: appinstalled');
        // onInstall();
        setInstalled(true);
      });
    } else {
      // iOS, macOS Safari & Firefox
      if (!isRunningStandalone() && !isSafariStandalone()) {
        setInstalled(false);
      }
    }

    if ('setAppBadge' in navigator) {
      if (!location.origin.startsWith('https://marketplace.syntech.info') && !location.origin.startsWith('http://')) {
        return;
      }
      // @ts-ignore
      navigator.setAppBadge(2);
    }
  }, []);

  const onClickInstall = useCallback(async () => {
    console.log('pwa: click', installEvent);
    // If there is no stashed "BeforeInstallPromptEvent", return.
    if (!installEvent) {
      return;
    }
    // Use the stashed "BeforeInstallPromptEvent" to prompt the user.
    // @ts-ignore
    installEvent.prompt();
    // @ts-ignore
    const result = await installEvent.userChoice;
    console.log('pwa: click result', { installEvent, result });
    // If the user installs the app, run "onInstall()".
    if (result.outcome === 'accepted') {
      setInstalled(true);
    }
  }, [installEvent]);

  return installEvent && !installed ? (
    <PWAInstallPopup open={open} onInstall={onClickInstall} onCancel={() => onToggle(false)} />
  ) : null;
};

export default PWAInstallPromt;
