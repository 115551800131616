"use client";
import { useCallback, useEffect, useState } from "react";
import cn from "@/libs/cn";
import InlineIcons from "../basic/InlineIcons";
import useClientContext from "@/context/ClientContext";

import styles from "./styles.module.scss";

function toTop() {
  document?.body.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "start",
  });
}

const STICKY_MENU_SELECTOR_ID = "StickyMenu";

const useIsScrolled = () => {
  const { isMobileSize } = useClientContext();
  const [scrolled, setScrolled] = useState(false);

  const checkIsScrolled = useCallback(() => {
    const panel = document.querySelector(`#${STICKY_MENU_SELECTOR_ID}`);

    if (isMobileSize) {
      setScrolled(!!document.documentElement.scrollTop);
    } else {
      setScrolled(!panel?.getBoundingClientRect().top);
    }
  }, [isMobileSize]);

  useEffect(() => {
    checkIsScrolled();
  }, [checkIsScrolled]);

  useEffect(() => {
    document.addEventListener("scroll", checkIsScrolled);

    return () => {
      document.removeEventListener("scroll", checkIsScrolled);
    };
  }, [checkIsScrolled]);

  return scrolled;
};

const ButtonScrollToTop = () => {
  const scrolled = useIsScrolled();

  return (
    <div className={cn(styles.ButtonScrollToTop, scrolled && styles.show)} onClick={toTop}>
      <InlineIcons.ArrowUp width={24} height={24} />
    </div>
  );
};

export default ButtonScrollToTop;
