export const MARKETPLACE_NAME = "MallPrix";
export const MARKETPLACE_EMAIL = "support@mallprix.com";
export const MARKETPLACE_TEL = "+380123456789";

export const MARKETPLACE_APPLE = "https://apple.com/";
export const MARKETPLACE_PLAYMARKET = "https://play.google.com/";
export const MARKETPLACE_FACEBOOK = "https://facebook.com/";
export const MARKETPLACE_TWITTER = "https://twitter.com/";
export const MARKETPLACE_INSTAGRAM = "https://instagram.com/";
export const MARKETPLACE_YOUTUBE = "https://www.youtube.com/";
export const MARKETPLACE_TELEGRAM = "https://telegram.org/";
export const MARKETPLACE_VIBER = "https://www.viber.com/en/";
export const MARKETPLACE_IOS = "https://www.viber.com/en/";
export const MARKETPLACE_ANDROID = "https://www.viber.com/en/";
